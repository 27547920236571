@import 'bootstrap/scss/dropdown';

.theme-light {
    --dropdown-bg: var(--color-bg-1);
    --dropdown-border-color: var(--input-border-color);
    --dropdown-link-hover-bg: var(--color-bg-2);
    --dropdown-header-color: var(--text-muted);
    --dropdown-shadow: 0 4px 16px -6px rgba(36, 41, 54, 0.2);
}

.theme-dark {
    --dropdown-bg: var(--color-bg-1);
    --dropdown-border-color: var(--input-border-color);
    --dropdown-link-hover-bg: var(--color-bg-2);
    --dropdown-header-color: var(--text-muted);
    --dropdown-shadow: 0 4px 16px -6px rgba(11, 12, 15, 0.8);
}

.dropdown-divider {
    margin: 0.25rem 0;
}

.dropdown-menu {
    border-radius: var(--popover-border-radius);
    box-shadow: var(--dropdown-shadow);
}
