:root {
    --btn-border-width: 1px;
    --btn-padding-x: 0.75rem;
    --btn-active-box-shadow: inset 0 3px 5px rgba(var(--black), 0.125);
}

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; // match .btn alignment given font-size hack above

    > .btn {
        position: relative;
        flex: 1 1 auto;

        // Bring the hover, focused, and "active" buttons to the front to overlay
        // the borders properly
        &:hover {
            z-index: 1;
        }
        &:focus,
        &:active,
        &.active {
            z-index: 1;
        }
    }
}

.btn-group {
    // Prevent double borders when buttons are next to each other
    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
        margin-left: calc(-1 * var(--btn-border-width));
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .btn {
    @extend .btn-sm;
}
.btn-group-lg > .btn {
    @extend .btn-lg;
}

//
// Split button dropdowns
//

.dropdown-toggle-split {
    padding-right: calc(var(--btn-padding-x) * 0.75);
    padding-left: calc(var(--btn-padding-x) * 0.75);

    &::after,
    .dropup &::after,
    .dropright &::after {
        margin-left: 0;
    }

    .dropleft &::before {
        margin-right: 0;
    }
}

// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
.btn-group.show .dropdown-toggle {
    box-shadow: var(--btn-active-box-shadow);

    // Show no shadow for `.btn-link` since it has no other button styles.
    &.btn-link {
        box-shadow: none;
    }
}

//
// Vertical button groups
//

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > .btn,
    > .btn-group {
        width: 100%;
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
        margin-top: var(--btn-border-width);
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
