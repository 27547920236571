:root {
    --code-font-family: sfmono-regular, consolas, menlo, dejavu sans mono, monospace;
    --code-font-size: #{(12/14)}em;
}

$code-font-family: var(--code-font-family);
$code-font-size: var(--code-font-size);

code,
.text-code {
    font-family: var(--code-font-family);
    font-size: var(--code-font-size);
    line-height: 1rem;
    white-space: pre;
}

.bg-code {
    background-color: var(--code-bg);
}

kbd {
    font-family: var(--code-font-family);
    font-size: var(--code-font-size);
    display: inline-block;
    line-height: (16/12);
    height: 1.125rem;
    padding: 0 0.25rem;
    margin: 0 0.125rem;
    vertical-align: middle;
    border-radius: 3px;
    color: var(--body-color);
    background-color: var(--color-bg-2);
    box-shadow: inset 0 -2px 0 var(--color-bg-3);
}

// Search examples that link to the results page should use this class.
.search-query-link {
    color: var(--body-color);
}

.search-filter-keyword {
    color: var(--search-filter-keyword-color);
}

.search-keyword {
    color: var(--search-keyword-color);
}

// Color of the `:` separator of the context dropdown, for example.
.search-filter-separator {
    color: var(--search-filter-separator-color);
}
